<template>
	<div class="floor-style-1">
		<div class="head-wrap" v-if="data.value.title.value.text" style="text-align: center;color:#776b58;">
			<div class="ibiu">
			<el-image  :src="require('@/assets/images/leftline.png')"></el-image>
			<h2 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }">				
			     {{ data.value.title.value.text }}		
			</h2>
			<el-image  :src="require('@/assets/images/rightline.png')"></el-image>
			</div>
		</div>
		<div class="jhiuq">
			<div class="left-wrap" v-if="data.value.leftImg.value.url">
				<img :src="$img(data.value.leftImg.value.url)" @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
			</div>
		<div class="body-wrap">
			
			<ul class="goods-list">
				<li v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)"
				:style="{'border-right': (index !==3&&index !==7) ? '0.5px #c6c4c4 solid': ''}" 
				>
					<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" /></div>
					<h3>{{ item.goods_name }}{{index}}</h3>
					<p class="desc">{{ item.introduction }}</p>
					<span class="price_list" :style="{'border-bottom': (index <4) ? '0.5px #c6c4c4 solid': ''}" >
					<p class="price">
						<span class="num">￥{{ item.discount_price }}元</span>
						<del>￥{{ item.market_price }}元</del>
					</p>
					<p class="gcart">
						 <img src="@/assets/images/iconcart.png" />
					</p>
					</span>
				</li>
			</ul>
			
		</div>
	<div class="right-wrap" v-if="data.value.bottomImg.value.url">
		<img :src="$img(data.value.bottomImg.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
	</div>
	</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
	name: 'floor-style-1',
	props: {
		data: {
			type: Object
		}
	},
	data() {
		return {};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage'])
	},
	methods: {
		goSku(skuId) {
			this.$router.pushToTab('/sku-' + skuId);
		},
		imageError(index) {
			this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		}
	}
};
</script>

<style lang="scss">
	.price_list{
		display: flex;
		    flex-direction: row;
		    justify-content: space-between;
		    align-items: center;
			padding: 0 10px;
	}
	.price{
		display: flex;
		    flex-direction: column;
		    align-content: flex-start;
		    align-items: flex-end;
	}
.floor-style-1 {
	.head-wrap h2 {
		line-height: 30px;
		color: #776b58;
		padding: 10px;
		// font-size: 18px;
		cursor: pointer;
		// width: 95%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
	.right-wrap {
		float: left;
		width: 334px;
		height: 614px;
		cursor: pointer;
		transition: all 0.2s linear;
		&:hover {
			z-index: 2;
			-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
			box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
			-webkit-transform: translate3d(0, -2px, 0);
			transform: translate3d(0, -2px, 0);
		}
		img {
			border-top-right-radius:20px;
			border-bottom-right-radius:20px;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
	.left-wrap {
		float: left;
		width: 334px;
		height: 614px;
		cursor: pointer;
		transition: all 0.2s linear;
		&:hover {
			z-index: 2;
			-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
			box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
			-webkit-transform: translate3d(0, -2px, 0);
			transform: translate3d(0, -2px, 0);
		}
		img {
			border-top-left-radius:20px;
			border-bottom-left-radius:20px;
			width: 100%;
			height: 100%;
			cursor: pointer;
		}
	}
	.body-wrap {
	width: 100%;
	
		.goods-list {
			// width: 100%;
			// margin-left: 235px;
			display: flex;
			flex-wrap: wrap;
			li {
				width: 191.9px;
				// margin-left: 19px;
				// margin-bottom: 20px;
				// border: 0.1em #555 solid;
				background: #fff;
				cursor: pointer;
				padding: 0px 0;
				position: relative;
				&:hover::after{
					content:'';
					position: absolute;
					top:0;
					left:0;
					height:100%;
					width:100%;
					z-index: 2;
					//border: 1px solid $base-color;
				}
				.img-wrap {
					width: 160px;
					height: 168px;
					margin: 0 auto 18px;
					text-align: center;
					line-height: 160px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				h3 {
					font-size: 12px;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 5px 15px;
				}
				.desc {
					margin: 0 30px 10px;
					height: 20px;
					font-size: 12px;
					color: #b0b0b0;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.price {
					margin: 0 10px 14px;
					text-align: center;
					color: $base-color;
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 118px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
	.ibiu{
		    display: flex;
		    justify-content: center;
		    align-items: center;
		    flex-direction: row;
	}
	.ibiu .el-image{
		line-height: 15px;
	}
	.jhiuq{
		    display: flex;
		    flex-direction: row;
		    justify-content: space-between;
	}
}
</style>
