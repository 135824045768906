<template>

    <div>
        <!--幻灯片开始-->
        <div class="main_01">
            <div class="fullSlide">
                <div class="bd">
                    <el-carousel height="360px" arrow="hover" v-loading="loadingAd" @change="handleChange">
                        <el-carousel-item v-for="item in adList" :key="item.adv_id">
                            <el-image :src="$img(item.adv_image)" fit="cover"
                                      @click="$router.pushToTab(item.adv_url.url)" />
                        </el-carousel-item>
                    </el-carousel>
                </div>
                <div class="hd">
                    <ul>
                        <li class="on"></li>
                        <li class=""></li>
                        <li class=""></li>
                    </ul>
                </div>

            </div>

            <div class="about">
                <div class="about_tit"><a href="">企业简介</a><i></i></div>
                <div class="about_box">
                    新疆格莱美特活性炭有限公司于2012年8月6日经呼图壁县工商行政管理局注册登记，注册号652323050005383，公司住册地，新疆昌吉州呼图壁县大丰工业园区横六路以北纵四路以西，占地300亩（土地以挂牌拍卖形式取得国有土地使用证），土地属性；工业用地。公司性质；有限责任公司，股东五位均为自然人，注册资金8000万元，公司项目为年产10万吨煤基活性炭20万吨活性石灰生产项目......
                    <router-link target="_blank" :to="{ path: '/cms/help-17'}">查看详情 ></router-link>
                </div>
            </div>
        </div>
        <!--幻灯片结束-->
        <div class="clear"></div>

        <!------行业资讯-------->
        <div class="hyzxtit">
            <h1><a href="">行业资讯</a></h1>
            <ul>

                <li  v-for="item in helpList"  ><router-link target="_blank" :to="{ path: '/cms/notice?id='+item.class_id}"> / {{item.class_name}}</router-link></li>

            </ul>
        </div>
        <div class="hyzxbox">
            <div class="hyzxbox_l">
                <div class="adv2" v-if="topAdlist[0]"><img :src="$img(topAdlist[0].adv_image)"></div>
                <div class="hyzxbox_l_l fl">
                    <div class="gldt">
                        <li v-for="(item,index) in helpList[0]['child_list']['list']" :key="item.id" v-if="index<7"><router-link target="_blank" :to="{ path: '/cms/notice-'+item.id}">{{item.title}}</router-link></li>
                    </div>
                    <div class="about_tit"><router-link target="_blank" :to="{ path: '/cms/notice/?id='+helpList[1].class_id}">{{helpList[1]['class_name']}}</router-link><i></i></div>
                    <div class="hydt">
                        <li v-for="(item,index) in helpList[1]['child_list']['list']" :key="item.id" v-if="index<6"><a href="">{{item.title}}</a></li>
                        <span v-if="index==0">{{item.description}}<router-link target="_blank" :to="{ path: '/cms/notice-'+item.id}">[详情]</router-link></span>

                    </div>
                </div>
                <div class="hyzxbox_l_r fr">
                    <div class="hyzh">
                        <div class="hyzh_t">
                            <div class="img">
                                <a href="" v-if="midAdlist[0]"><img :src="$img(midAdlist[0].adv_image)"></a>
                                <a href="" v-if="midAdlist[1]"><img :src="$img(midAdlist[1].adv_image)"></a>
                            </div>
                            <h1>新疆煤化所主持制定的超级电容器多孔</h1>
                        </div>
                        <li v-for="(item,index) in helpList[2]['child_list']['list']" :key="item.id" v-if="index<4"><router-link target="_blank" :to="{ path: '/cms/notice-'+item.id}">{{item.title}}</router-link></li>
                    </div>
                    <div class="about_tit hq"><router-link target="_blank" :to="{ path:  '/cms/notice/?id='+helpList[3].class_id}">{{helpList[3]['class_name']}}</router-link></div>

                    <div class="gldt hq hq1">
                        <div class="adv3">
                            <a href=""><img src="../../assets/image//adv2.jpg"></a>
                        </div>
                        <li v-for="(item,index) in helpList[3]['child_list']['list']" :key="item.id" v-if="index<2"><router-link target="_blank" :to="{ path: '/cms/notice-'+item.id}">{{item.title}}</router-link></li>
                    </div>

                </div>


            </div>

            <div class="hyzxbox_r">
                <div class="about_tit"><router-link target="_blank" :to="{ path:  '/cms/notice/?id='+helpList[4].class_id}">{{helpList[4]['class_name']}}</router-link><i></i></div>


                <div class="sczs">
                    <li v-for="(item,index) in helpList[4]['child_list']['list']" :key="item.id" v-if="index<9"><router-link target="_blank" :to="{ path: '/cms/notice-'+item.id}">{{item.title}}</router-link>
                    </li>
                </div>


                <div class="about_tit"><router-link target="_blank" :to="{ path:  '/cms/notice/?id='+helpList[5].class_id}">{{helpList[5]['class_name']}}</router-link><i></i></div>

                <div class="hydt">
                    <li v-for="(item,index) in helpList[5]['child_list']['list']" :key="item.id" v-if="index<5"><router-link target="_blank" :to="{ path: '/cms/notice-'+item.id}">{{item.title}}</router-link></li>
                    <span v-if="index==0">{{item.description}}<a href="">[详情]</a></span>
                </div>

            </div>

        </div>




        <!--主体开始-->
        <div class="main">
            <!----煤基活性炭---->
            <div class="floor" v-for="(item, floorIndex) in goodsCategory1" :key="floorIndex">
                <div class="floor_tit">
                    <ul class="menu">
                        <li class="on"> <router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }" target="_blank">{{ item.category_name }}</router-link></li>
                    </ul>
                    <router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }" target="_blank">更多 &gt;</router-link></div>
                <div class="lc_list">
                    <router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }" target="_blank"><img :src="$img(item.image_adv)"></router-link>
                    <a   v-for="(items, indexs) in item.goods" :key="indexs" @click="goSku(items.sku_id)" href="javascript:;">
                        <img @click="goSku(items.sku_id)"  :src="$img(items.sku_image)" class="trans">
                        <p>{{items.goods_name}}</p>
                        <span>商城价 <i>￥{{items.price}}</i></span>
                        <span>市场价 <s>￥{{items.market_price}}</s></span>
                    </a>

                </div>

                <!------adv------>
                </br>
                <div class="adv1"   >
                    <a href=""  ><img :src="$img(item.advs)"/></a>
                </div>

            </div>

    </div>
    </div>
</template>

<script>
    import floorStyle1 from "./components/floor-style-1.vue"
    import floorStyle2 from "./components/floor-style-2.vue"
    import floorStyle3 from "./components/floor-style-3.vue"
    import index from "./_index/index.js"
    import { tree } from "@/api/goods/goodscategory"
    import { noticesLists } from "@/api/cms/notice";
    import { adList } from "@/api/website"

    export default {
        name: "index",
        components: {
            floorStyle1,
            floorStyle2,
            floorStyle3
        },
        data: () => {
            return {
                goodsCategory1: [],
                categoryFixed: false,
                clickIndex: 0,
                loading: true,
                helpList: [],
                adLeftList:[{adv_image:''}],
                topAdlist:[],
                midAdlist:[]
            }
        },
        created() {
            this.getGoodsCategory();
            this.getBigAdList();

        },
        methods: {
            showAdv(floorIndex) {
                // 根据楼层索引判断是否显示广告
                // 例如，每两个楼层显示一个广告
                return (floorIndex + 1) % 2 === 0;
            },
            getAdvImage(floorIndex) {
                // 根据楼层索引获取广告图片
                // 这里只是一个示例，你需要根据实际情况来实现
                // 假设 adLeftList 是一个包含广告数据的数组
                const advIndex = Math.floor((floorIndex + 1) / 2); // 向下取整得到广告索引
               if(this.adLeftList[advIndex]){
                   return this.adLeftList[advIndex]['adv_image'];
               }
               return  '';

            },
            goSku(skuId) {
                this.$router.pushToTab('/sku-' + skuId);
            },
            getBigAdList() {
               //获取所有首页广告位
                adList({
                    keyword: "NS_PC_INDEX_MID_LEFT"
                })
                    .then(adv => {
                        this.topAdlist = adv.data.adv_list
                        for (let i = 0; i < this.topAdlist.length; i++) {
                            if (this.topAdlist[i].adv_url) this.topAdlist[i].adv_url = JSON.parse(this.topAdlist[i].adv_url)
                        }
                        console.log('11111',this.topAdlist)
                        this.loadingAd = false

                    })
                    .catch(err => {
                        this.loadingAd = false
                    })
                adList({
                    keyword: "NS_PC_INDEX_MID_RIGHT"
                })
                    .then(adv => {
                        this.midAdlist = adv.data.adv_list
                        for (let i = 0; i < this.midAdlist.length; i++) {
                            if (this.midAdlist[i].adv_url) this.midAdlist[i].adv_url = JSON.parse(this.midAdlist[i].adv_url)
                        }
                        this.loadingAd = false

                    })
                    .catch(err => {
                        this.loadingAd = false
                    })
            },
            // 分类列表
            getGoodsCategory() {
                tree({
                    level: 3,
                    template: 2
                })
                    .then(res => {
                        if (res.code == 0) {

                            adList({
                                keyword: "NS_PC_INDEX_TOP"
                            })
                                .then(adv => {
                                    this.goodsCategory1 = res.data
                                    this.adLeftList = adv.data.adv_list
                                    for (let i = 0; i < this.adLeftList.length; i++) {
                                        if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
                                    }
                                    for (let ii = 0; ii < this.goodsCategory1.length; ii++) {
                                         if((ii+1)%2==0){
                                             this.goodsCategory1[ii]['advs']=this.adLeftList[((ii+1)/2)-1].adv_image??'';
                                         }
                                    }
                                    console.log(this.goodsCategory1)
                                    this.loadingAd = false

                                })
                                .catch(err => {
                                    this.loadingAd = false
                                })
                        }
                        this.loading = false
                    })
                    .catch(err => {
                        this.$message.error(err.message)
                        this.loading = false
                    })
            }},
        mixins: [index]
    }
</script>

<style lang="scss" scoped>
    @import './_index/index.scss';
</style>

<style lang="scss" scoped>
    .fixed-box {
        background-color: #efe7da;
    }

    .count-down {
        span {
            display: inline-block;
            width: 22px;
            height: 22px;
            line-height: 22px;
            text-align: center;
            background: #383838;
            color: #ffffff;
            border-radius: 2px;
        }
    }

    .more {
        width: 1200px;
        margin: auto;
        display: flex;
        justify-content: flex-end;

        a {
            color: #ff547b
        }
    }

    .goods-info {
        width: 1200px;
        margin: 5px auto 40px;
        display: flex;
        flex-wrap: wrap;

        .item {
            width: 202px;
            margin: 10px 20px 0 0;
            border: 1px solid #eeeeee;
            padding: 10px;
            position: relative;

            &:nth-child(5 n) {
                margin-right: initial !important;
            }

            &:hover {
                border: 1px solid $base-color;
            }

            .img-wrap {
                width: 198px;
                height: 198px;
                cursor: pointer;
            }

            .goods-name {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                cursor: pointer;

                &:hover {
                    color: $base-color;
                }
            }

            .price-wrap {
                display: flex;
                align-items: center;

                .price {
                    display: flex;
                    color: $base-color;
                    font-size: $ns-font-size-lg;

                    p {
                        font-size: $ns-font-size-base;
                        display: flex;
                        align-items: flex-end;
                    }
                }

                .market-price {
                    color: #838383;
                    text-decoration: line-through;
                    margin-left: 10px;
                }
            }

            .sale-num {
                display: flex;
                color: #838383;

                p {
                    color: #4759a8;
                }
            }

            .saling {
                display: flex;
                font-size: $ns-font-size-sm;
                line-height: 1;

                .free-shipping {
                    background: $base-color;
                    color: #ffffff;
                    padding: 3px 4px;
                    border-radius: 2px;
                    margin-right: 5px;
                }

                .promotion-type {
                    color: $base-color;
                    border: 1px solid $base-color;
                    display: flex;
                    align-items: center;
                    padding: 1px;
                }
            }
        }
    }

    .el-row {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .el-col {
        border-radius: 4px;
    }

    .bg-purple-dark {
        height: 50px;
        background-color: #4CAF50;
        padding: 5px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .bg-purple-dark a {
        padding: 5px 20px;
        font-size: 20px;
        height: 40px;
        line-height: 40px;
        color: #fff;
        font-weight: 400;
    }

    .bg-purple-dark .active {
        color: #000;
        background-color: #fff;
    }

    .bg-purple {
        background: #fff;
        display: flex;
        height: 415px !important;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        flex-wrap: nowrap;

    }

    .bg-purple-light {
        // background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .demonstration {
        display: block;
        width: 100%;
        background-color: #0000006b;
        color: #fff;
        position: absolute;
        bottom: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    images_list {
        position: relative;
        height: 140px;
        width: 100%;
        margin-top: 20px;
    }

    .news_list {
        padding: 10px 2px;

        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

    }

    .news_title {

        font-size: 18px;
        color: #737373;
        height: 40px;
        width: 50%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
    }

    @charset "utf-8";
    /* CSS Document */
    body {
        font-family: "宋体", Helvetica, Arial, sans-serif;
        font-size: 12px;
        margin: 0;
        padding: 0;
        color: #333;
        background-color: #fff;
    }

    div {
        float: none;
        margin: 0px auto;
        padding: 0;
    }

    body > div {
        margin: 0 auto
    }

    form, img, ul, ol, li, dl, dt, dd, input, p, br, button {
        margin: 0;
        padding: 0;
        border: 0;
    }

    ul, li, ol {
        list-style: none;
    }

    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        padding: 0;
    }

    a {
        text-decoration: none;
        color: #333
    }

    a:hover {
        text-decoration: none;
    }

    i, em {
        font-style: normal;
    }

    img {
        border: 0;
    }

    .clear {
        clear: both;
        line-height: 0;
        height: 0;
        display: block;
    }

    .fr {
        float: right;
    }

    .fl {
        float: left;
    }

    a:focus {
        outline: none;
    }

    a:hover {
        -webkit-transition: all 0.5s;
        -webkit-transiton-delay: 0.6s;
    }

    .trans {
        transition: all .3s ease;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        -webkit-backface-visibility: hidden;
    }

    .trans:hover {
        transform: scale(1.04, 1.04);
        -ms-transform: scale(1.04, 1.04);
        -webkit-transform: scale(1.04, 1.04);
    }

    /**头部样式**/

    .ztoolbar_bg {
        width: 100%;
        height: 40px;
        line-height: 40px;
        min-width: 1190px;
        background: #f0f0f0;
        overflow: hidden;
    }

    .ztoolbar {
        width: 1190px;
        height: auto;
        color: #494949;
        font: 14px/40px '宋体';
    }

    .ztoolbar .fl:nth-child(1) a {
        color: #ff7200;
    }

    .ztoolbar .fl:nth-child(1) a:hover {
        color: #666666
    }

    .ztoolbar .zright {
        color: #494949;
        font-family: "宋体"
    }

    .ztoolbar .zright a {
        display: inline-block;
        margin: 0 5px;
        color: #525252;
        font: 12px/40px '宋体';
    }

    .ztoolbar .zright a:hover {
        color: #f98e0a;
    }

    .topbj {
        background: #fff;
    }

    .ztop {
        height: 147px;
        width: 1190px;
    }

    .ztop .zlogo {
        margin-top: 29px;
        overflow: hidden;
    }

    /*搜索开始*/
    .zsearch {
        float: left;
        height: 85px;
        width: 527px;
        margin: 40px 0px 0px 90px;
    }

    .zsearch form input[type="text"] {
        width: 430px;
        height: 44px;
        line-height: 33px;
        border: 2px #bd271f solid;
        background-color: #fff;
        color: #c8c8c8;
        text-indent: 1em;
        outline: none;
        float: left;
        font: 14px /44px '宋体';
        color: #CCC;
    }

    .zsearch form input[type="submit"] {
        width: 90px;
        height: 48px;
        border: none;
        float: left;
        outline: none;
        font-size: 14px;
        letter-spacing: 4px;
        color: #ffffff;
        background: url(../../assets/image/search_btn.png) no-repeat center #bd271f;
        cursor: pointer;
    }

    .zsearch_key {
        margin-top: 58px;
        margin-right: 50px;
        color: #b2b2b2;
        font-size: 14px;
        margin-left: 20px;
        font-family: "微软雅黑"
    }

    .zsearch_key a {
        color: #b2b2b2;
        text-decoration: none;
        padding-right: 12px;
    }

    .zsearch_key a:nth-child(2) {
        color: #bd271f
    }

    .zsearch_key a:hover {
        color: #ff7e26;
    }

    /*二维码*/
    .ewm {
        width: 180px;
        margin-top: 35px;
    }

    .ewm h1 {
        float: left;
        margin: 22px 0 0 0;
    }

    .ewm .img {
        width: 78px;
        height: 78px;
        float: right;
        border: #f3f3f3 solid 2px;
    }

    /*导航*/
    .nav_bg {
        width: 100%;
        height: 44px;
        background: #458ce3;
    }

    .navmenu {
        width: 1190px;
        line-height: 44px;
        margin: 0px auto;
        position: relative;
    }

    .navmenu .navr {
        height: 44px;
        line-height: 44px;
    }

    .navmenu .navr a {
        font: 16px/44px '微软雅黑';
        color: #fff;
        height: 44px;
        display: block;
        float: left;
        min-width: 120px;
        text-align: center;
        margin: 0 21px;
    }

    .navmenu .navr a:hover {
        background: #bd271f;
    }

    .navmenu .navr a:nth-child(1) {
        margin-left: 0;
    }

    /*底部开始*/
    .zfoot_bg {
        background-color: #494949;
        width: 100%;
        height: auto;
        min-width: 1190px;
        overflow: hidden;
        margin: 0 auto;
    }

    .zsever {
        width: 1190px;
        margin: 0px auto;
        height: 97px;
    }

    .icon {
        padding: 34px 0px 0px;
        overflow: hidden;
    }

    .icon li {
        float: left;
        width: 300px;
        margin-left: 85px;
    }

    .icon li h1 {
        width: 39px;
        height: 44px;
        line-height: 44px;
        font-weight: normal;
        border-radius: 50px;
        text-align: center;
        display: block;
        float: left;
    }

    .icon li span {
        font: 18px/44px '';
        color: #9a9a9a;
        display: block;
        float: left;
        height: 55px;
        margin-left: 15px;
    }

    .icon li.i1 {
        background: url(../../assets/image/i1.png) no-repeat;
    }

    .icon li.i2 {
        background: url(../../assets/image/i2.png) no-repeat;
    }

    .icon li.i3 {
        background: url(../../assets/image/i3.png) no-repeat;
    }

    .icon img {
        float: left;
        height: 52px;
        vertical-align: top;
        -webkit-transition: transform .3s;
        -moz-transition: transform .3s;
        -ms-transition: transform .3s;
        -o-transition: transform .3s;
        transition: transform .3s;
    }

    .zfoot {
        width: 1190px;
        margin: 0 auto;
        padding: 20px 0;
        font-family: "微软雅黑";
        font-size: 14px;
        border-top: #5a5a5a solid 1px;
        color: #5fac47;
    }

    .zfoot p {
        line-height: 26px;
        text-align: center;
        color: #a8a8a8;
    }

    .zfoot ul {
        padding-left: 408px;
    }

    .zfoot ul li {
        float: left;
        padding: 0px 10px;
        border-right: 1px #a8a8a8 solid;
        height: 20px;
        line-height: 20px;
    }

    .zfoot ul li a {
        color: #a8a8a8;
        font-size: 14px;
    }

    .zfoot ul li a:hover {
        color: #fff;
    }

    .zfoot ul li:last-child {
        border: none;
    }

    .fhtop {
        position: fixed;
        right: 240px;
        bottom: 21%;
        z-index: 9999;
    }

    @charset "utf-8";
    .main {
        width: 100%;
        margin: 0 auto;
        background: #f3f3f3;
        overflow: hidden;
    }

    .main_01 {
        width: 1190px;
        margin: 30px auto 0;
    }

    /*首页幻灯片开始*/
    .fullSlide {
        width: 753px;
        height: 277px;
        position: relative;
        float: left;
        overflow: hidden;
    }

    .fullSlide .bd {
        margin: 0 auto;
        position: relative;
        z-index: 0;
        overflow: hidden;
    }

    .fullSlide .bd ul {
        width: 100% !important;
    }

    .fullSlide .bd li {
        width: 100% !important;
        height: 277px;
        overflow: hidden;
        text-align: center;
    }

    .fullSlide .bd li a {
        display: block;
        height: 277px;
    }

    .fullSlide .hd {
        width: 753px;
        z-index: 1;
        bottom: 4px;
        margin: 0 auto;
        height: 30px;
        line-height: 30px;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
    }

    .fullSlide .hd ul {
        text-align: center;
    }

    .fullSlide .hd ul li {
        cursor: pointer;
        display: inline-block;
        *display: inline;
        zoom: 1;
        width: 11px;
        height: 11px;
        border-radius: 50px;
        margin: 4px;
        background: #fff;
        overflow: hidden;
        line-height: 9999px;
    }

    .fullSlide .hd ul .on {
        width: 21px;
        height: 11px;
        background-color: #3772b2;
        border-radius: 50px;
    }

    /*幻灯片结束*/

    /*企业简介*/
    .about {
        width: 412px;
        height: 277px;
        float: right;
    }

    .about_tit {
        width: 100%;
    }

    .about_tit a {
        color: #bd271f;
        font: bold 20px/40px '微软雅黑';
        display: block;
    }

    .about_tit i {
        width: 76px;
        height: 2px;
        background: #e2e2e2;
        display: block;
        margin: 5px 0 0 0;
    }

    .about_box {
        width: 100%;
        overflow: hidden;
        color: #5f5f5f;
        font: 15px/32px '微软雅黑';
        margin: 5px 0 0 0;
    }

    .about_box a {
        color: #ff0000;
        font: 15px/32px '微软雅黑';
    }

    .about_box a:hover {
        color: #569fdd;
    }

    /*广告*/
    .adv1 {
        width: 1190px;
        margin: 30px auto 0;
        overflow: hidden;
    }

    .adv2 {
        width: 753px;
        height: 80px;
        overflow: hidden;
    }

    .adv3 {
        width: 345px;
        height: 102px;
        overflow: hidden;
        margin: 10px 0 12px 0;
    }

    /*产品楼层*/
    .floor {
        width: 1190px;
        margin: 30px auto 0;
        overflow: hidden;
        background: #fff;
    }

    .floor_tit {
        height: 62px;
        width: 1152px;
        border-bottom: 2px solid #bd271f;
        overflow: hidden;
    }

    .floor_tit i {
        display: inline-block;
        float: left;
        font: bold 20px/50px '';
        color: #ff4200;
        margin-left: 5px;
    }

    .floor_tit font {
        display: inline-block;
        float: left;
        font-size: 19px;
        color: #0065ab;
        font-weight: bold;
        font-family: "微软雅黑"
    }

    .floor_tit a {
        font-size: 16px;
        color: #7e7e7e;
        float: right;
        height: 42px;
        line-height: 42px !important;
        padding-top: 12px;
        font-family: "微软雅黑";
    }

    .floor_tit a:hover {
        color: #ff0000;
    }

    .tabs {
        width: 1190px;
        overflow: hidden;
    }

    .menu {
        height: 42px;
        line-height: 42px;
        padding-top: 20px;
        float: left;
        margin-left: 10px;
    }

    .menu li a {
        display: inline-block;
        float: left;
        width: auto;
        font: 18px/42px '微软雅黑';
        color: #ff0000;
        cursor: pointer;
        text-align: center;
        overflow: hidden;
        height: 42px;
        padding: 0 12px;
    }

    .menu .on a {
        color: #fff;
        background: #bd271f;
        min-width: 92px;
    }

    .menu span {
        display: inline-block;
        float: left;
        font-size: 14px;
        color: #595757;
    }

    .lc_list {
        margin: 20px auto 0;
        height: auto;
        width: 1152px;
    }

    .lc_list a {
        width: 207px;
        height: 288px;
        display: block;
        float: left;
        margin: 0 30px 26px 0;
    }

    .lc_list a:nth-child(1) {
        width: 209px;
        height: 287px;
        margin: 0;
        overflow: hidden;
        float: left;
    }

    .lc_list a:nth-child(1) img {
        width: 100%;
        height: 100%;
    }

    .lc_list a:nth-child(2) {
        margin-left: 25px;
    }

    .lc_list a:nth-child(5) {
        margin-right: 0;
    }

    .lc_list img {
        width: 206px;
        height: 206px;
    }

    .lc_list p {
        display: inline-block;
        width: 100%;
        height: 25px;
        overflow: hidden;
        color: #000000;
        font: 14px/25px '微软雅黑';
        margin-top: 10px;
    }

    .lc_list p span {
        color: #40b01e;
    }

    .lc_list i {
        font: 15px/25px '微软雅黑';
        color: #ec2038;
    }

    .lc_list s {
        font: 13px/25px '微软雅黑';
        color: #8f8f8f;
    }

    .lc_list span {
        width: 100%;
        font: 14px/20px '微软雅黑';
        color: #727272;
        display: block;
        text-align: left
    }

    .lc_list strong {
        color: #bfbfbf;
        font: 12px/20px '微软雅黑';
        float: left;
        width: 35%;
        text-align: right
    }

    .lc_list p:hover {
        color: #ff0000;
    }


    /*行业资讯*/
    .hyzxtit {
        width: 1190px;
        height: 45px;
        overflow: hidden;
        margin: 30px auto 0
    }

    .hyzxtit h1 {
        font: 23px/45px '';
        color: #555555;
        height: 45px;
        overflow: hidden;
        float: left;
    }

    .hyzxtit ul {
        float: left;
        margin-left: 10px;
    }

    .hyzxtit ul li {
        display: block;
        font: 16px/45px '';
        height: 45px;
        color: #666666;
        float: left;
        margin: 0 10px 0 10px;
    }

    .hyzxtit ul li a {
        font: 16px/45px '';
        color: #666666;
        height: 45px;
        overflow: hidden;
        display: block;
    }

    .hyzxtit ul li a:hover {
        color: #ff0000;
    }

    .hyzxbox {
        width: 1190px;
        overflow: hidden;
        margin: 15px auto 0;
    }

    .hyzxbox_l {
        float: left;
    }

    .hyzxbox_r {
        width: 390px;
        float: left;
        margin: 0 0 0 30px
    }

    .hyzxbox_l_l {
        width: 367px;
        border-right: #e5e5e5 solid 1px;
        margin: 25px 0 25px 0;
    }

    /*格莱动态*/
    .gldt {
        width: 367px;
        border-bottom: #e5e5e5 solid 1px;
        padding-bottom: 10px;
        margin-bottom: 11px;
    }

    .gldt li {
        overflow: hidden;
        height: 36px;
        background: url(../../assets/image/icon2.png) left center no-repeat;
        padding-left: 12px;
        /* 确保文本不会换行 */
        white-space: nowrap;
        /* 隐藏超出容器的文本 */
        overflow: hidden;
        /* 当文本溢出容器时显示省略号 */
        text-overflow: ellipsis;
    }

    .gldt li a {
        color: #5f5f5f;
        font: 15px/36px '微软雅黑';
    }

    .gldt li:nth-child(1) {
        background: url(../../assets/image/icon1.png) left center no-repeat;
        padding-left: 15px;
    }

    .gldt li:nth-child(1) a {
        font: 18px/36px '微软雅黑';
        color: #2c2c2c;
    }

    .gldt li a:hover {
        color: #ff0000;
    }

    /*行业动态*/
    .hydt {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .hydt li {
        height: 36px;
        background: url(../../assets/image/icon2.png) left center no-repeat;
        padding-left: 12px;
        overflow: hidden;
        /* 确保文本不会换行 */
        white-space: nowrap;
        /* 隐藏超出容器的文本 */
        overflow: hidden;
        /* 当文本溢出容器时显示省略号 */
        text-overflow: ellipsis;
    }

    .hydt li a {
        color: #5f5f5f;
        font: 15px/36px '微软雅黑';
    }

    .hydt li:nth-child(1) {
        background: url(../../assets/image/icon1.png) left center no-repeat;
        padding-left: 15px;
    }

    .hydt li:nth-child(1) a {
        font: 18px/36px '微软雅黑';
        color: #2c2c2c;
    }

    .hydt li:nth-child(2) {
        height: 70px;
    }

    .hydt li a:hover {
        color: #ff0000;
    }

    .hydt span {
        color: #959595;
        font: 13px/24px '';
        margin: 0 15px 0 15px;
        overflow: hidden;
        display: block;
    }

    .hydt span a {
        color: #ff0000;
        font: 13px/24px '';
    }

    /*行业展会*/
    .hyzh {
        width: 385px;
        margin: 25px 0 10px 0;
        overflow: hidden;
        border-bottom: #e5e5e5 solid 1px;
        padding-bottom: 16px;
    }

    .hyzh_t {
        width: 345px;
        height: 144px;
        margin: 0 0 0 39px;
    }

    .hyzh_t .img {
        width: 100%;
        height: 104px;
    }

    .hyzh_t .img a {
        width: 168px;
        height: 104px;
        overflow: hidden;
    }

    .hyzh_t .img a:nth-child(1) {
        float: left;
    }

    .hyzh_t .img a:nth-child(2) {
        float: right;
    }

    .hyzh_t h1 {
        font: 18px/36px '微软雅黑';
        color: #2c2c2c;
        height: 36px;
        overflow: hidden;
        display: block;
        margin: 8px 0 0 0;
    }

    .hyzh li {
        height: 36px;
        background: url(../../assets/image/icon3.png) left center no-repeat;
        padding-left: 20px;
        overflow: hidden;
        margin-left: 38px;
        /* 确保文本不会换行 */
        white-space: nowrap;
        /* 隐藏超出容器的文本 */
        overflow: hidden;
        /* 当文本溢出容器时显示省略号 */
        text-overflow: ellipsis;
    }

    .hyzh li a {
        color: #5f5f5f;
        font: 15px/36px '微软雅黑';
    }

    .hyzh li a:hover {
        color: #ff0000;
    }

    .hydt li:nth-child(1) a {
        font: 18px/36px '微软雅黑';
        color: #2c2c2c;
    }

    .hydt li:nth-child(2) {
        height: 70px;
    }

    /*行情速递*/
    .hq {
        margin: 0 0 0 35px;
    }

    .hq1 {
        border: 0;
    }

    /*市场综述*/
    .sczs {
        width: 100%;
        margin: 0 0 10px 0;
    }

    .sczs li {
        height: 36px;
        background: url(../../assets/image/icon2.png) left center no-repeat;
        padding-left: 12px;
        overflow: hidden;
        /* 确保文本不会换行 */
        white-space: nowrap;
        /* 隐藏超出容器的文本 */
        overflow: hidden;
        /* 当文本溢出容器时显示省略号 */
        text-overflow: ellipsis;
    }

    .sczs li a {
        color: #5f5f5f;
        font: 15px/36px '微软雅黑';
    }

    .sczs li a:hover {
        color: #ff0000;
    }


    /*友情链接*/
    .link {
        width: 1190px;
        overflow: hidden;
        margin: 30px auto 0;
    }

    .link .tit {
        width: 100%;
        height: 45px;
    }

    .link .tit h1 {
        font: 23px/45px '';
        color: #555555;
        height: 45px;
        overflow: hidden;
        float: left;
    }

    .link .tit a {
        font: 16px/45px '';
        color: #a0a0a0;
        height: 45px;
        overflow: hidden;
        float: right;
    }

    .link .tit a:hover {
        color: #bd271f;
    }

    .link .box {
        width: 100%;
        height: 110px;
        background: #fff;
        margin: 10px auto 30px;
    }

    .link .box a {
        width: 126px;
        height: 70px;
        display: block;
        overflow: hidden;
        float: left;
        margin: 20px 0 0 20px;
    }

    .link .box img {
        width: 100%;
        height: 100%;
        transition: all 0.4s ease-in-out;
        -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
        -o-transition: all 0.4s ease-in-out;
    }

    .link .box img:hover {
        transform: scale(1.1);
        -webkit-transform: scale(1.1);
        -moz-transform: scale(1.1);
        -o-transform: scale(1.1);
        -ms-transform: scale(1.1);
    }

    .top-content {
        font-size: 14px;
    }

    .ns-text-color {
        color: #e64b08 !important;
    }

    .header-top {
        width: 100%;
        height: 31px;
        font-size: 12px;
        background-color: #fff;

        .el-dropdown {
            font-size: $ns-font-size-sm;
        }

        .top-content {
            width: $width;
            height: 100%;
            margin: 0 auto;

            .top-left {
                height: 100%;
                float: left;
                height: 100%;
                line-height: 31px;

                .change-city {
                    cursor: pointer;
                    margin-left: 5px;

                    &:hover {
                        color: $base-color;
                        border-color: $base-color;
                    }
                }
            }

            .top-right {
                height: 100%;
                float: right;
                font-size: $ns-font-size-sm;
                color: $ns-text-color-black;

                a {
                    float: left;
                    line-height: 31px;
                    padding: 0 10px;

                    &:hover {
                        color: $base-color;
                    }
                }

                div {
                    float: left;
                    height: 100%;
                    margin-left: 10px;
                    cursor: pointer;
                    line-height: 31px;
                    padding: 0 5px;

                    &.member-info {
                        margin-left: 0;

                        span:first-child {
                            margin-right: 10px;
                        }
                    }

                    span:hover {
                        color: $base-color;
                    }

                    &.el-dropdown:hover {
                        background-color: #fff;
                    }
                }
            }
        }
    }

    .mobile-qrcode {
        padding: 10px 0;
    }
</style>